<template>
  <div class="home">
    <div class="section">
      <div class="home-left">
        <p>Hey there,</p>
        <p>I'm Ozan Bilgic</p>
        <br />
        <p>
          <router-link to="/about"><a>More About Me</a></router-link>
        </p>
      </div>
      <div class="home-right">
        <div class="photo">
          <img src="../../assets/picture.png" alt="Picture" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
     
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 80vh;
  background-color: rgb(58, 55, 55);
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section .home-left p:nth-child(1) {
  font-size: 25px;
}

.section .home-left p {
  color: white;
  font-family: "Questrial", sans-serif;
  line-height: 50px;
  font-size: 40px;
  transition: 500ms;
}

.section .home-left p a {
  color: rgb(80, 226, 245);
  text-decoration: none;
  padding: 10px;
  border-radius: 40px;
  background-color: rgb(88, 83, 85);
  font-size: 25px;
  transition: 0.5s;
}

.section .home-left p a:hover {
  background-color: rgb(58, 55, 55);
}

.home-right .photo {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  overflow: hidden;
  transition: 500ms;
}

.home-right .photo img {
  position: relative;
  top: -85px;
}

@media only screen and (max-width: 1200px) {
  .section .home-left p:nth-child(1) {
    font-size: 25px;
  }

  .section .home-left p {
    font-size: 30px;
  }
    .home-right .photo {
  width: 400px;
  height: 400px;
  }

}

@media only screen and (max-width: 900px) {
  .home .section {
    display: flex;
    flex-direction: column;
  }

  .section .home-left p:nth-child(1) {
    font-size: 25px;
  }

  .section .home-left p {
    font-size: 40px;
  }

  .home .section .home-left {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 450px) {
  .section .home-left p:nth-child(1) {
    font-size: 25px;
  }

  .section .home-left p {
    font-size: 30px;
    line-height: 30px;
  }
    .home-right .photo {
  width: 250px;
  height: 250px;
  }

  .section .home-left p a {
  padding: 5px;
  border-radius: 20px;
  font-size: 25px;
}

  .home .section .home-left {
    margin-bottom: 30px;
  }

  .home-right .photo img {
    top: -110px;
    left: -70px;
}
}

</style>
