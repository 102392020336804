<template>
  <div class="contact">
    <div class="baslik">
      <h1>Contact Me</h1>
    </div>
    <div class="iletisim">
      <div class="social" v-for="(item, index) in consoc" :key="index">
        <a :href="[item.link]" target="_blank" class="icon" v-html="item.icon"></a>
        <a :href="[item.link]" target="_blank">{{item.name}}</a>
      </div>
    </div>
    <div class="form"> 
      <div class="client">
        <div class="girdi">
          <input
            type="text"
            v-model="name"
            class="name"
            placeholder="Name"
            required
          />
          <input
            type="text"
            v-model="mail"
            class="mail"
            placeholder="E-Mail"
            required
          />
          <input
            type="text"
            v-model="subject"
            class="subject"
            placeholder="Subject"
          />
        </div>
        <div class="yazialan">
          <textarea
            v-model="message"
            class="message"
            cols="30"
            rows="5"
            placeholder="Message"
            required
          ></textarea>
          <button class="submit" @click="add">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get, child } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};
const app = initializeApp(firebaseConfig);
const dbRef = getDatabase(app);
export default {
  data() {
    return {
      name: "",
      mail: "",
      subject: "",
      message: "",
      messg: [],
      consoc: []
    };
  },
  methods: {
    add() {
      if(this.name !== '' && this.mail !== '' & this.message !== ''){
      const postListRef = ref(dbRef, "contact/", );
      const newPostRef = push(postListRef);
      set(newPostRef, {
        name: this.name,
        mail: this.mail,
        subject: this.subject,
        message: this.message,
      });

      this.name = '';
      this.mail = '';
      this.subject = '';
      this.message = '';
      }
    }
  },
  mounted() {
    const app = initializeApp(firebaseConfig);
    const dbRef = ref(getDatabase(app));

    get(child(dbRef, "contact-social"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.consoc = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>


<style scoped>
.contact {
  width: 100%;
  min-height: 80vh;
  height: auto;
}
.baslik {
    text-align: center;
    font-family: 'Questrial', sans-serif;
    color: rgb(80, 226, 245);
    line-height: 100px;
}

.iletisim {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.iletisim .social {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 150px;
    height: 100px;
    background-color: rgb(88, 83, 85);
    margin: 20px;
    border-radius: 10px;
}

.iletisim .social .icon{
    font-size: 40px;
    color: white;
    cursor: pointer;
    transition: 0.5s;
}

.iletisim .social .icon:hover {
    color: rgb(80, 226, 245);
}

.iletisim .social a {
    font-family: 'Questrial', sans-serif;
    color: rgb(29, 28, 28);
    text-decoration: none;
    font-size: 20px;
}

.client {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    border: none;
    margin-top: 50px;
}

.client .girdi {
    display: flex;
    flex-direction: column;
}

.client .girdi input {
    width: 300px;
    height: 40px;
    border-radius: 40px;
    text-indent: 15px;
    background-color: rgb(88, 83, 85);
    color: white;
    outline: none;
    font-size: 15px;
    border: none;
    font-family: 'Questrial', sans-serif;
    margin-bottom: 25px;
    margin-right: 25px;
}

.form input::placeholder {
    color: white;
    background-color: rgb(88, 83, 85);
}

.yazialan {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}

.form textarea {
    order: 3;
    width: 300px;
    height: 170px;
    border-radius: 15px;
    line-height: 40px;
    text-indent: 15px;
    background-color: rgb(88, 83, 85);
    color: white;
    outline: none;
    border: none;
    resize: none;
    font-size: 15px;
    font-family: 'Questrial', sans-serif;
}

.submit {
    width: 100px;
    height: 40px;
    border-radius: 40px;
    text-indent: 0px;
    background-color: rgb(88, 83, 85);
    color: white;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    border: none;
    font-family: 'Questrial', sans-serif;
    margin-top: 20px;
    transition: 0.5s;
}

.submit:hover {
    color: rgb(80, 226, 245);
}

.form textarea::placeholder {
    color: white;
    background-color: rgb(88, 83, 85);
}

@media only screen and (max-width: 690px) {
  .client .girdi input {
    margin-right: auto;
    margin-left: auto;
}
}
@media only screen and (max-width: 420px) {
      .iletisim .social{
    width: 100px;
    height: 90px;
}

.client .girdi input {
    width: 250px;
    height: 40px;
    margin-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
}

.form textarea {
    width: 250px;
    height: 170px;
}
}

</style>
