<template>
  <div class="admin-control">
    <div class="menu">
      <ul>
        <li @click="comps = 'AdminSkills'" :style="comps === 'AdminSkills' ? 'color: white' : '' ">Skills</li>
        <li @click="comps = 'AdminExperience'" :style="comps === 'AdminExperience' ? 'color: white' : '' ">Experience</li>
        <li @click="comps = 'AdminEducation'" :style="comps === 'AdminEducation' ? 'color: white' : '' ">Education</li>
        <li @click="comps = 'AdminCertificates'" :style="comps === 'AdminCertificates' ? 'color: white' : '' ">Certificates</li>
      </ul>
    </div>
    <div class="settings">
      <admin-skills v-show="comps === 'AdminSkills'"/>
      <admin-experience v-show="comps === 'AdminExperience'"/>
      <admin-education v-show="comps === 'AdminEducation'"/>
      <admin-certificates v-show="comps === 'AdminCertificates'"/>
    </div>
  </div>
</template>

<script>
import AdminSkills from './AdminSkills.vue'
import AdminExperience from './AdminExperience.vue'
import AdminEducation from './AdminEducation.vue'
import AdminCertificates from './AdminCertificate.vue'

    export default {
        data() {
            return {
                comps: 'AdminSkills',
            }
        },
        components: {
            AdminSkills,
            AdminExperience,
            AdminEducation,
            AdminCertificates
        }
    }

</script>

<style scoped>
.admin-control {
  width: 100%;
  min-height: 80vh;
}

.menu {
  width: 100%;
  min-height: 10vh;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu ul {
  display: flex;
}

.menu ul li {
  list-style: none;
  padding: 20px;
  font-family: "Questrial", sans-serif;
  font-size: 19px;
  transition: 400ms;
  margin: 5px;
  color: rgb(80, 226, 245);
}

.menu ul li::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: rgb(99, 98, 98);
  transition: 400ms;
}

.menu ul li:hover {
  cursor: pointer;
}

.menu ul li:hover::after {
  width: 100%;
}

.settings {
  width: 100%;
  min-height: 70vh;
}
</style>