<template>
    <div id="egitim" class="education">
        <div class="card" v-for="(item, index) in edu" :key="index">
          <div class="place">
            <p class="university">University</p>
            <p class="name">{{item.university}}</p>
          </div>
          <div class="since">
            <p>{{item.date}}</p>
          </div>
          <div class="detail">
            <br />
            <p>
              {{item.detail}}  
            </p>
          </div>
        </div>
      </div>
</template>


<script>

import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};

export default {
  data() {
    return {
      edu: [],
    };
  },
  mounted() {
    const app = initializeApp(firebaseConfig);
    const dbRef = ref(getDatabase(app));

    get(child(dbRef, "education"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.edu = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>

.education {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 100px;
}

.card {
  width: 500px;
  min-height: 120px;
  height: auto;
  font-family: "Questrial", sans-serif;
  background-color: rgb(66, 61, 61);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.card .place {
  position: relative;
  float: left;
  font-family: "Questrial", sans-serif;
}

.card .place .university {
  color: rgb(189, 183, 183);
}

.card .place .name {
  color: white;
  font-size: 20px;
  line-height: 25px;
}
.card .since {
  position: relative;
  float: right;
  color: white;
  font-family: "Questrial", sans-serif;
}

.card .detail {
  position: relative;
  float: left;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

@media only screen and (max-width: 570px) {

   .card {
  width: 330px;
  min-height: 150px;
  height: auto;
  padding: 20px;
  margin: 20px auto;
}

.card .since {
    float: left;
    font-size: 18px;
}

.card .detail {
    font-size: 20px;
    line-height: 25px;
}
}
@media only screen and (max-width: 375px) {
   .card {
  min-width: 220px;
  width: auto;
  min-height: 150px;
  height: auto;
  padding: 20px;
  margin: 20px 5px;
}
}

</style>