<template>
  <div class="admin-control">
    <div class="menu">
      <ul>
        <li @click="comps = 'AdminWeb'" :style="comps === 'AdminWeb' ? 'color: white' : '' ">Web Site</li>
        <li @click="comps = 'AdminDesign'" :style="comps === 'AdminDesign' ? 'color: white' : '' ">Design</li>
        <li @click="comps = 'AdminApp'" :style="comps === 'AdminApp' ? 'color: white' : '' ">Web Application</li>
      </ul>
    </div>
    <div class="settings">
      <admin-web v-show="comps === 'AdminWeb'"/>
      <admin-design v-show="comps === 'AdminDesign'"/>
      <admin-app v-show="comps === 'AdminApp'"/>
    </div>
  </div>
</template>

<script>
import AdminWeb from './AdminWeb.vue'
import AdminDesign from './AdminDesign.vue'
import AdminApp from './AdminApp.vue'

    export default {
        data() {
            return {
                comps: 'AdminWeb',
            }
        },
        components: {
            AdminWeb,
            AdminDesign,
            AdminApp
        }
    }

</script>

<style scoped>
.admin-control {
  width: 100%;
  min-height: 80vh;
}

.menu {
  width: 100%;
  min-height: 10vh;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu ul {
  display: flex;
}

.menu ul li {
  list-style: none;
  padding: 20px;
  font-family: "Questrial", sans-serif;
  font-size: 19px;
  transition: 400ms;
  margin: 5px;
  color: rgb(80, 226, 245);
}

.menu ul li::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: rgb(99, 98, 98);
  transition: 400ms;
}

.menu ul li:hover {
  cursor: pointer;
}

.menu ul li:hover::after {
  width: 100%;
}

.settings {
  width: 100%;
  min-height: 70vh;
}
</style>