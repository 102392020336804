<template>
  <div id="app">
    <Header/>
      <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/user/Header.vue'
import Footer from './components/user/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
}


</script>

<style>
* {
  padding: 0;
  margin: 0;
}

#app {
  background-color: rgb(58, 55, 55);
}


</style>
