<template>
  <div id="app" class="app">

    <div class="orn">
      <a href="https://www.instagram.com/p/CbLFmDpFcRa/" target="_blank"
        ><img src="dosyalar/react-todo.png" alt="To-Do App"
      /></a>
      <a href="https://www.instagram.com/p/CbLFmDpFcRa/" target="_blank"
        >React.js To-Do</a
      >
    </div>
    <div class="orn">
      <a href="dosyalar/app/oyun.html" target="_blank"
        ><img src="dosyalar/oyun.png" alt="Rock - Scissors - Paper"
      /></a>
      <a href="dosyalar/app/oyun.html" target="_blank"
        >Rock - Scissors - Paper</a
      >
    </div>
    <div class="orn">
      <a href="dosyalar/app/memorygame.html" target="_blank"
        ><img src="dosyalar/memory.png" alt="Memory Game"
      /></a>
      <a href="dosyalar/app/memorygame.html" target="_blank">Memory Game</a>
    </div>
    <div class="orn">
      <a href="dosyalar/app/lottery.html" target="_blank"
        ><img src="dosyalar/lottery.png" alt="Lottery Game"
      /></a>
      <a href="dosyalar/app/lottery.html" target="_blank">Lottery Game</a>
    </div>
    <div class="orn">
      <a href="dosyalar/app/calculator.html" target="_blank"
        ><img src="dosyalar/app/calculatorphoto.png" alt="IPhone Calculator"
      /></a>
      <a href="dosyalar/app/calculator.html" target="_blank"
        >IPhone Calculator</a
      >
    </div>
    <div class="orn">
      <a href="dosyalar/app/saat.html" target="_blank"
        ><img src="dosyalar/app/wallclock.png" alt="Wall Clock"
      /></a>
      <a href="dosyalar/app/saat.html" target="_blank">Wall Clock</a>
    </div>
    <div class="orn">
      <a href="dosyalar/app/oynayankutu.html" target="_blank"
        ><img src="dosyalar/app/movingbox.png" alt="Calculator"
      /></a>
      <a href="dosyalar/app/oynayankutu.html" target="_blank">Moving Box</a>
    </div>
    <div class="orn">
      <a href="dosyalar/app/shoppinglist.html" target="_blank"
        ><img src="dosyalar/todo1.png" alt="To do"
      /></a>
      <a href="dosyalar/app/shoppinglist.html" target="_blank">To do</a>
    </div>
    <div class="orn">
      <router-link to="/calendar">
        <a target="_blank"
          ><img src="dosyalar/app/calendar.png" alt="Calendar"
        /></a>
        <router-link to="/calendar">Dynamic Calendar</router-link>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>


<style scoped>
.app {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 70px;
}

.orn {
  text-align: center;
  width: 400px;
  height: 400px;
  margin: 30px;
}

.orn img {
  width: 400px;
  height: 350px;
  transition: 0.5s;
  object-fit: cover;
}

.orn img:hover {
  opacity: 0.7;
}
.orn a {
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  color: white;
  font-family: "Questrial", sans-serif;
}

@media only screen and (max-width: 400px) {

  .orn {
    text-align: center;
    width: 270px;
    height: 250px;
    margin: 30px;
  }

  .orn img {
    width: 270px;
    height: 250px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1820px) {
  #app {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 2300px) {
  #app {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>