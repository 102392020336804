<template>
  <div id="sertifika" class="certificates">
    <div class="cardcer" v-for="(item, index) in cert" :key="index">
      <div class="from">
        <p class="aus">From</p>
        <p class="office">{{item.from}}</p>
      </div>
      <div class="when">
        <p>{{item.date}}</p>
      </div>
      <div class="cername">
        <br /><br /><br />
        {{item.detail}}
      </div>
    </div>
  </div>
</template>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};

export default {
  data() {
    return {
      cert: [],
    };
  },
  mounted() {
    const app = initializeApp(firebaseConfig);
    const dbRef = ref(getDatabase(app));

    get(child(dbRef, "certificates"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.cert = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>
.certificates {
  max-width: 1300px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 130px;
  margin-left: auto;
  margin-right: auto;
}

.cardcer {
  width: 500px;
  height: 100px;
  font-family: "Questrial", sans-serif;
  background-color: rgb(66, 61, 61);
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
}

.cardcer .from {
  position: relative;
  float: left;
  font-family: "Questrial", sans-serif;
}

.cardcer .from .aus {
  color: rgb(189, 183, 183);
}

.cardcer .from .office {
  color: white;
  font-size: 20px;
  line-height: 25px;
}
.cardcer .when {
  position: relative;
  float: right;
  color: white;
  font-family: "Questrial", sans-serif;
}

.cardcer .cername {
  position: relative;
  color: white;
  line-height: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

@media only screen and (max-width: 1150px) {
  .certificates {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .cardcer {
    width: 500px;
    height: 100px;
    font-family: "Questrial", sans-serif;
    background-color: rgb(66, 61, 61);
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
  }
}
</style>