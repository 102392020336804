import Home from './components/user/Home'
import About from './components/user/About'
import Portfolio from './components/user/Portfolio'
import Contact from './components/user/Contact'
import AdminControl from './components/admin/AdminControl'
import GeneralSettings from './components/admin/GeneralSettings'
import Calendar from './components/user/Calendar'
import NotFound from './components/user/NotFound'



export const routes = [
    {
        path: '',
        component: Home,
        meta: { title: 'Home' }
    },
    {
        path: '/about',
        component: About,
        meta: { title: 'About' }
    },
    {
        path: '/portfolio',
        component: Portfolio,
        meta: {title: 'Portfolio'}
    },
    {
        path: '/contact',
        component: Contact,
        meta: { title: 'Contact' }
    },
    {
        path: '/general-settings',
        component: GeneralSettings,
    },
    {
        path: '/admin-control',
        component: AdminControl,
    },
    {
        path: '/calendar',
        component: Calendar,
    },
    { path: "*", component: NotFound }
]
