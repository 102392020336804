import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { routes } from './routes'


Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode : 'history'
});

new Vue({
  router, 
  render: h => h(App),
}).$mount('#app')

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = to.meta.title;
  });
});
