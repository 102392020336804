<template>
  <div>
    <input type="file" id="dosya" @change="putDesign()" />
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default {
  data() {
    return {
      fileName: "",
      resim: "",
      photos: [],
    };
  },
  methods: {
    putDesign() {
      const file = document.getElementById("dosya").files[0];
      this.fileName = file.name;
      let designRef = ref(storage, "design/" + this.fileName);
      uploadBytes(designRef, file).then(() => {
        console.log("Uploaded a blob or file!");
      });
    },
  },
  mounted() {
    // Create a reference under which you want to list
    const listRef = ref(storage, "design/");

    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach(() => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          getDownloadURL(itemRef).then(downloadURL=>{
              this.photos.push(downloadURL);
          })

        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });

  },
};
</script>