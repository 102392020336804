<template>
  <div class="footer">
    <div class="footer-left">
      <ul>
        <li v-for="(item, index) in confooter" :key="index">
          <a :href="[item.link]" target="_blank"
            v-html="item.icon"></a>
        </li>
      </ul>
    </div>
    <div class="footer-right">
      <p>&copy; ozanbilgic</p>
    </div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};

export default {
  data() {
    return {
      confooter: [],
    };
  },
  mounted() {
    const app = initializeApp(firebaseConfig);
    const dbRef = ref(getDatabase(app));

    get(child(dbRef, "contact-footer"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.confooter = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left {
  margin-left: 30px;
}

.footer-left ul {
  display: flex;
}

.footer-left ul li {
  list-style: none;
  margin-left: 15px;
}

.footer-left ul li a {
  font-size: 20px;
  color: white;
  transition: 400ms;
  border: none;
  outline: none;
}

.footer-left ul li a:hover {
  color: rgb(80, 226, 245);
}

.footer-right {
  margin-right: 45px;
}

.footer-right p {
  font-size: 20px;
  color: white;
  font-family: "Questrial", sans-serif;
}

@media only screen and (max-width: 450px) {
  .footer-left {
    margin-left: 10px;
  }
  .footer-right {
    margin-right: 15px;
  }
}
</style>
