<template>
  <div class="admin-contact">
    <div class="skillTable">
      <p
        style="
          text-align: center;
          color: #50e2f5;
          margin-bottom: 30px;
          font-size: 30px;
          font-family: 'Questrial', sans-serif;
        "
      >
        Messages
      </p>
      <table>
        <tr>
          <th>From</th>
          <th>Mail</th>
          <th>Detail</th>
          <th>Message</th>
          <th>Delete</th>
        </tr>
        <tr v-for="(item, index) in messg" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.mail }}</td>
          <td>{{ item.subject }}</td>
          <td>{{ item.message }}</td>
          <td class="delete" @click="dlt(index)">Delete</td>
        </tr>
      </table>
    </div>
    <p
      style="
        text-align: center;
        color: #50e2f5;
        margin-bottom: 30px;
        font-size: 30px;
        font-family: 'Questrial', sans-serif;
      "
    >
      Add Social Media
    </p>

    <div class="add">
      <input type="text" v-model="link" placeholder="Link" /><br />
      <input type="text" v-model="icon" placeholder="Icon" /><br />
      <input type="text" v-model="name" placeholder="Name" /><br />
      <button @click.prevent="add">ADD</button><br />
    </div>

    <div class="skillTable">
      <table>
        <tr>
          <th>Name</th>
          <th>icon</th>
          <th>link</th>
          <th>Delete</th>
        </tr>
        <tr v-for="(item, index) in consoc" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.icon }}</td>
          <td>{{ item.link }}</td>
          <td class="delete" @click="move(index)">Delete</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  remove,
  push,
} from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};

const app = initializeApp(firebaseConfig);
const dbRef = getDatabase(app);

export default {
  data() {
    return {
      link: "",
      icon: "",
      name: "",
      messg: [],
      consoc: [],
    };
  },
  methods: {
    add() {
      const postListRef = ref(dbRef, "contact-social/");
      const newPostRef = push(postListRef);
      set(newPostRef, {
        link: this.link,
        icon: this.icon,
        name: this.name,
      });
    },
    dlt(a) {
      remove(ref(dbRef, "contact/" + a));
      console.log(a);
    },
    move(b) {
      remove(ref(dbRef, "contact-social/" + b));
    },
  },
  mounted() {
    const app = initializeApp(firebaseConfig);
    const dbRef = ref(getDatabase(app));

    get(child(dbRef, "contact"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.messg = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    get(child(dbRef, "contact-social"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.consoc = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>
.admin-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admin-contact input {
  width: 300px;
  height: 30px;
  text-indent: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  margin: 10px;
}

.admin-contact button {
  width: 300px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #50e2f5;
  outline: none;
  border-radius: 20px;
  margin: 10px;
  color: white;
  transition: 400ms;
}

.admin-contact button:hover {
  cursor: pointer;
  background-color: #50e2f5;
}

.skillTable {
  width: 80%;
  margin-top: 40px;
  margin-bottom: 40px;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

table th {
  text-transform: uppercase;
  padding: 10px;
  color: #50e2f5;
  font-size: 20px;
}

table,
td,
th {
  border: 1px solid white;
}

table td,
th {
  font-family: "Questrial", sans-serif;
}

table td {
  padding: 10px;
  color: white;
}

.delete {
  background-color: #50e2f5;
  cursor: pointer;
}

.delete:hover {
  background-color: transparent;
}
</style>