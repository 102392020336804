<template>
  <div>
    <div v-if="login" class="admin-control">
      <div class="menu">
        <ul>
          <li
            @click="comps = 'GeneralSettings'"
            :style="comps === 'GeneralSettings' ? 'color: white' : ''"
          >
            General
          </li>
          <li
            @click="comps = 'AboutSettings'"
            :style="comps === 'AboutSettings' ? 'color: white' : ''"
          >
            About
          </li>
          <li
            @click="comps = 'PortfolioSettings'"
            :style="comps === 'PortfolioSettings' ? 'color: white' : ''"
          >
            Portfolio
          </li>
          <li
            @click="comps = 'ContactSettings'"
            :style="comps === 'ContactSettings' ? 'color: white' : ''"
          >
            Contact
          </li>
        </ul>
        <br>
                <button @click="signOut">Sign Out</button>

      </div>
      <div class="settings">
        <general-settings v-show="comps === 'GeneralSettings'" />
        <about-settings v-show="comps === 'AboutSettings'" />
        <portfolio-settings v-show="comps === 'PortfolioSettings'" />
        <contact-settings v-show="comps === 'ContactSettings'" />
      </div>
    </div>
    <div v-else>
      <div class="login">
        <h1>Log In</h1>
        <input type="email" v-model="mail"  placeholder="E-Mail"/>
        <input type="password" v-model="password" placeholder="Password"/>
        <button @click="run">Giriş Yap</button>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralSettings from "./GeneralSettings.vue";
import AboutSettings from "./AboutSettings.vue";
import PortfolioSettings from "./PortfolioSettings.vue";
import ContactSettings from "./ContactSettings.vue";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

// const auth = getAuth();
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in, see docs for a list of available properties
//     // https://firebase.google.com/docs/reference/js/firebase.User
    
//     // ...
//   } else {
//     // User is signed out
//     // ...
//   }
// });

export default {
  data() {
    return {
      comps: "GeneralSettings",
      login: false,
      mail: "",
      password: "",
    };
  },
  components: {
    GeneralSettings,
    AboutSettings,
    PortfolioSettings,
    ContactSettings,
  },
  methods: {
    run() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.mail, this.password)
        .then(() => {
          // Signed in
          this.login = true;
          this.mail = '';
          this.password = '';
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage, errorCode);
        });
    },
    signOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          this.login = false;
        })
        .catch((error) => {
          // An error happened.
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.admin-control {
  width: 100%;
  min-height: 80vh;
}

.menu {
  width: 100%;
  min-height: 10vh;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu button {
  width: 100px;
  background-color: rgb(99, 98, 98);
  color: rgb(80, 226, 245);
  border: 0;
  outline: 0;
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
}

.menu button:hover {
  color: white;
}

.menu ul {
  display: flex;
}

.menu ul li {
  list-style: none;
  padding: 20px;
  font-family: "Questrial", sans-serif;
  font-size: 19px;
  transition: 400ms;
  margin: 5px;
  color: rgb(80, 226, 245);
}

.menu ul li::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: rgb(99, 98, 98);
  transition: 400ms;
}

.menu ul li:hover {
  cursor: pointer;
}

.menu ul li:hover::after {
  width: 100%;
}

.settings {
  width: 100%;
  min-height: 70vh;
}

.login {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login h1 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(80, 226, 245);
  margin-bottom: 25px;
}

.login input {
  margin-bottom: 20px;
  width: 200px;
  height: 25px;
  border-radius: 25px;
  text-indent: 10px;
  border: 0;
  outline: 0;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.login button {
  width: 120px;
  height: 35px;
  background-color: #636262;
  border: 0;
  outline: 0;
  border-radius: 25px;
  cursor: pointer;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
  color: white;
}

.login button:hover {
  color: aqua;
}
</style>