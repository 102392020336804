<template>
  <div class="about">
    <div class="title">
      <p>About Me</p>
    </div>
    <div class="text">
      <div class="photo">
        <img src="dosyalar/about-image.jpg" alt="Picture" />
      </div>

      <!-- details about me -->
      <div class="detail">
        <p>
          Hey there! I'm Ozan, <br />
          I have a passion for web developing, front-end development, and design.
          <br />
          I had the chance to use Wordpress, Next.js, TypeScript, Fluent UI, DHTMLX, Wijmo, GIT, and I used Cypress
          for end-to-end testing in the previous companies I worked.
          <br/>
          I like to learn new things and improve my skills. Other than the libraries above, I like 
          using Material UI and I had some basic personal projects by using Vue.js.
           <br />
           I had the chance to use some design tools when I was working as a designer in university.
            <br />
          - Adobe Photoshop, Adobe Illustrator, Adobe Animate, Camtasia <br />

          I like playing basketball, electric guitar and acoustic guitar, video
          games. I also like hiking, jogging and biking. <br/>
          I graduated from Anadolu University, department of Computer Education 
and Instructional Technology in 2021 with a GPA of 3.64 / 4.00.
        </p>
        <div class="buttons">
          <a href="dosyalar/cv.pdf" target="_blank">Download CV</a>
          <router-link to="/contact"><a>Contact</a></router-link>
        </div>
      </div>
    </div>
    <!-- information -->
    <div class="information">
      <ul>
        <!--<li @click="comp = 'Skills'" :style="comp === 'Skills' ? 'color: white' : '' ">Skills</li>-->
        <li @click="comp = 'Experience'" :style="comp === 'Experience' ? 'color: white' : '' ">Experience</li>
        <li @click="comp = 'Education'" :style="comp === 'Education' ? 'color: white' : '' ">Education</li>
        <li @click="comp = 'Certificates'" :style="comp === 'Certificates' ? 'color: white' : '' ">Certificates</li>
      </ul>
      <!-- <Skills v-show="comp === 'Skills'"/> -->
       <Experience v-show="comp === 'Experience'"/>
       <Education v-show="comp === 'Education'"/>
       <Certificates v-show="comp === 'Certificates'"/>
    </div>
  </div>
</template>

<script>

import Experience from "./Experience.vue"
import Education from "./Education.vue"
import Certificates from "./Certificates.vue"


export default {
  components: {
    
    Experience,
    Education,
    Certificates,
  },
  data() {
    return {
      comp: "Experience",
    };
  },
 };
</script>

<style scoped>

.about {
  width: 100%;
  min-height: 80vh;
  height: auto;
}
.title {
  text-align: center;
  font-family: "Questrial", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: rgb(80, 226, 245);
  line-height: 100px;
}

.text {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  top: 40px;
}

.photo {
  border: 10px solid rgb(105, 95, 95);
  width: 400px;
  height: 400px;
  overflow: hidden;
  margin-right: 100px;
}

.photo img {
  position: relative;
  transition: 300ms;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail {
  width: 700px;
  text-align: justify;
  line-height: 40px;
  font-size: 20px;
  color: white;
  font-family: "Questrial", sans-serif;
  transition: 300ms;
}

.buttons {
  margin-top: 40px;
}

.buttons a:nth-child(1) {
  text-decoration: none;
  color: rgb(80, 226, 245);
  background-color: rgb(88, 83, 85);
  padding: 10px;
  border-radius: 40px;
  transition: 1s;
}

.buttons a:nth-child(2) {
  text-decoration: none;
  margin-left: 20px;

}

.buttons a:hover {
  background-color: transparent;
}

.information {
  position: relative;
  top: 70px;
}

.information ul {
  display: flex;
  justify-content: center;
}

.information ul li {
  list-style: none;
  color: rgb(80, 226, 245);
  padding: 10px;
  font-size: 20px;
  font-family: "Questrial", sans-serif;
  cursor: pointer;
}

@media only screen and (max-width: 1220px) {

.detail {
  width: 800px;
  margin-top: 40px;
  margin-left: 25px;
  margin-right: 25px;
  transition: 300ms;
}

.buttons {
  margin-top: 50px;
}

}

@media only screen and (max-width: 770px) {

.information ul li {
  
  padding: 10px;
}

 .photo {
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

.text {
  top: 10px;
}
}

@media only screen and (max-width: 450px) {
  
.information ul li {
  
  padding: 5px;
  font-size: 15px;
}
}

@media only screen and (max-width: 325px) {

  .detail {
    font-size: 15px;
    line-height: 30px;
  }

.buttons {
  font-size: 15px;
}

.information ul li {
  
  padding: 5px;
  font-size: 13px;
}
}
</style> 