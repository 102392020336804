<template>
    <div id="deneyim" class="experience">
        <div class="kart" v-for="(item, index) in expers" :key="index">
          <div class="yer">
            <p class="pozisyon">{{item.position}}</p>
            <p class="isyeri">{{item.company}}</p>
          </div>
          <div class="tarih">
            <p>{{item.between}}</p>
          </div>
          <div class="aciklama">
            <br>
            <span v-html="item.detail"></span>
          </div>
        </div>
      </div>
</template>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};

export default {
  data() {
    return {
      expers: [],
    };
  },
  mounted() {
    const app = initializeApp(firebaseConfig);
    const dbRef = ref(getDatabase(app));

    get(child(dbRef, "experience"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.expers = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>

.experience {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 100px;
}

.kart {
  width: 500px;
  min-height: 100px;
  font-family: "Questrial", sans-serif;
  background-color: rgb(66, 61, 61);
  padding: 20px;
  border-radius: 10px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.kart .yer {
  position: relative;
  float: left;
  font-family: "Questrial", sans-serif;
}

.kart .yer .pozisyon {
  color: white;
  font-size: 20px;
}

.kart .yer .isyeri {
  color: rgb(189, 183, 183);
  line-height: 25px;
}
.kart .tarih {
  position: relative;
  float: right;
  color: white;
  font-family: "Questrial", sans-serif;
}

.kart .aciklama {
  position: relative;
  float: left;
  color: white;
  line-height: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

@media only screen and (min-width: 1710px) {
  .experience {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 770px) {

      .experience {
    
    margin-bottom: 50px;
  }

    .kart {
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 30px;
}

.kart .aciklama {
    line-height: 25px;
}

}
@media only screen and (max-width: 325px) {
  .alan {
  width: 250px;
  border-radius: 50px;
  background-color: rgb(66, 61, 61);
  margin: 15px;
}

.kart .tarih {
      font-size: 15px;
    float: left;
}
}
</style>
