<template>
  <div class="header" id="header">
    <div class="header-left">
      <p><router-link to="/">ozan</router-link></p>
    </div>
    <div class="header-right">
      <div class="menu">
        <i class="fas fa-bars" @click="open"></i>
        <ul id="list">
          <router-link active-class="active" to="/" exact tag="li">
            <a @click="close">Home</a>
          </router-link>
          <router-link active-class="active" to="/about" tag="li">
            <a @click="close">About</a>
          </router-link>
          <router-link active-class="active" to="/portfolio" tag="li">
            <a @click="close">Portfolio</a>
          </router-link>
          <router-link active-class="active" to="/contact" tag="li">
            <a @click="close">Contact</a>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    open: function () {
      var b = document.getElementById("header");
      var list = document.getElementById("list");

      if (b.className === "header") {
        b.className += " resp";
        list.style.display = "flex";
        list.style.top = 0;
      } else {
        b.className = "header";
        list.style.top = -100 + "%";
      }
    },
    close() {
      if (window.innerWidth < 900) {
        var list = document.getElementById("list");
        var b = document.getElementById("header");
        list.style.top = -100 + "%";
        b.className = "header";
      }
    },
  },
};
</script>

<style>
.header {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 500ms;
}

.header-left {
  margin-left: 30px;
}

.header-left p a {
  color: rgb(80, 226, 245);
  font-size: 40px;
  text-decoration: none;
  font-family: "Questrial", sans-serif;
}

.header-right ul {
  background-color: rgb(99, 98, 98);
  margin-right: 20px;
  border-radius: 40px;
  display: flex;
}

.header-right .menu .fas {
  display: none;
}

.header-right ul li {
  list-style: none;
  margin-right: 20px;
  margin-left: 20px;
  padding: 12px;
}

.header-right ul li a {
  text-decoration: none;
  color: rgb(80, 226, 245);
  font-family: "Questrial", sans-serif;
  font-size: 20px;
}

.header-right ul li a:hover {
  color: white;
}

.active a {
  color: white !important;
}

@media only screen and (max-width: 900px) {
  .header {
    align-items: flex-start;
  }

  .header-left,
  .fas {
    line-height: 80px;
  }

  .header-right {
    background-color: transparent;
  }

  .header-right .menu .fa-bars {
    color: white;
    font-size: 30px;
    margin-right: 20px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000;
  }

  .header-right .menu .fa-bars {
    display: block;
  }

  .header-right ul {
    position: fixed;
    background-color: transparent;
    width: 100%;
    height: 100vh;
    opacity: 0.9;
    top: -100%;
    left: 0;
    transition: 800ms;
    background-color: rgb(63, 62, 62);
    z-index: 100;
    border-radius: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-right ul li a {
    font-size: 50px;
  }

  .header-right i:hover {
    cursor: pointer;
  }

  .header.resp ul {
    display: flex;
    top: 0px;
    transition: 800ms;
  }
}
</style>

 