<template>
  <div id="poster" class="poster">
    <div class="kart" v-for="item, index in photos" :key="index">
      <a :href="item" target="_blank"
        ><img :src="item" alt="Design"
      /></a>
      <a :href="item" target="_blank">Show</a>
    </div>
  </div>
</template>

<script>

  import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default {
  data() {
    return {
      fileName: "",
      resim: "",
      photos: [],
    };
  },
  mounted() {
    // Create a reference under which you want to list
    const listRef = ref(storage, "design/");

    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach(() => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          getDownloadURL(itemRef).then(downloadURL=>{
              this.photos.push(downloadURL);
          })

        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });

  },
};

</script>


<style scoped>
.poster {
    display: flex;
    margin-top: 40px;
    flex-wrap:  wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 100px;
}

.kart {
    text-align: center;
    width: 400px;
    height: 500px;
    margin: 30px;
    background-color: rgb(99, 98, 98);
    border-radius: 10px;
}

.kart img {
    width: 400px;
    height: 500px;
    transition: 0.5s;
    object-fit: contain;
}

.kart img:hover {
    opacity: 0.7;
}
.kart a {
    text-decoration: none;
    font-size: 20px;
    line-height: 30px;
    color: white;
    font-family: 'Questrial', sans-serif;
}

@media only screen and (max-width: 375px) {
  .poster {
    min-width: 280px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}
.kart {
  position: relative;
    text-align: center;
    min-width: 250px;
    height: 500px;
    margin: 10px;
    margin-top: 30px;
        border-radius: 10px;

}

.kart img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    transition: 0.5s;
}
}

@media only screen and (min-width: 1800px) {

  .poster {
    width: 78%;
    margin-right: auto;
    margin-left: auto;
  
  }

}

@media only screen and (min-width: 2300px) {

  .poster {
    width: 65%;
    margin-right: auto;
    margin-left: auto;
  
  }

}
</style>