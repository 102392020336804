<template>
  <div class="adminskills">
    <div class="add">
      <input type="text" v-model="id" placeholder="id" /><br />
      <input type="text" v-model="university" placeholder="University" /><br />
      <input type="text" v-model="date" placeholder="Date" /><br />
      <input type="text" v-model="detail" placeholder="Detail" /><br />
      <button @click.prevent="add">ADD</button><br>
      <button @click.prevent="dlt">DELETE</button>
    </div>

    <div class="skillTable">
      <table>
        <tr>
          <th>Index</th>
          <th>University</th>
          <th>Date</th>
          <th>Detail</th>
        </tr>
        <tr v-for="(item, index) in edu" :key="index">
          <td>{{ index }}</td>
          <td>{{ item.university }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.detail }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, child, get,remove } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDDQLlluO4FusOQ8ucJvD_svdMJE5dkOlw",
  authDomain: "portfolio-4dd8c.firebaseapp.com",
  databaseURL:
    "https://portfolio-4dd8c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4dd8c",
  storageBucket: "portfolio-4dd8c.appspot.com",
  messagingSenderId: "237808071168",
  appId: "1:237808071168:web:006dc98a6c3272fbd39ee5",
};
const app = initializeApp(firebaseConfig);
const dbRef = getDatabase(app);
export default {
  data() {
    return {
      id: "",
      university: "",
      date: "",
      detail: "",
      edu: [],
    };
  },
  methods: {
    add() {
      set(ref(dbRef, "education/" + this.id), {
        university: this.university,
        date: this.date,
        detail: this.detail,
      });
    },
    dlt() {
     remove(ref(dbRef, "education/" + this.id));
}
  },
  mounted() {
    const app = initializeApp(firebaseConfig);
    const dbRef = ref(getDatabase(app));

    get(child(dbRef, "education"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.edu = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>
.adminskills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.adminskills input {
  width: 300px;
  height: 30px;
  text-indent: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  margin: 10px;
}

.adminskills button {
  width: 300px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #50e2f5;
  outline: none;
  border-radius: 20px;
  margin: 10px;
  color: white;
  transition: 400ms;
}

.adminskills button:hover {
  cursor: pointer;
  background-color: #50e2f5;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

table th {
  text-transform: uppercase;
  padding: 10px;
  color: #50e2f5;
  font-size: 20px;
}

table,
td,
th {
  border: 1px solid white;
}

table td,
th {
  font-family: "Questrial", sans-serif;
}

table td {
  padding: 10px;
  color: white;
}
</style>

