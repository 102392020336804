<template>
 <div class="portfolio">
    <div class="title">
      <p>My Portfolio</p>
    </div>
    <!-- information -->
    <div class="information">
      <ul>
        <li @click="comp = 'Website'" :style="comp === 'Website' ? 'color: white' : '' ">Web Site</li>
        <li @click="comp = 'Design'" :style="comp === 'Design' ? 'color: white' : '' ">Design</li>
        <li @click="comp = 'Webapp'" :style="comp === 'Webapp' ? 'color: white' : '' ">Web Application</li>
      </ul>
       <Website v-show="comp === 'Website'"/>
       <Design v-show="comp === 'Design'"/>
       <Webapp v-show="comp === 'Webapp'"/>
    </div>
  </div>
</template>

<script>

import Website from "./Website.vue"
import Design from "./Design.vue"
import Webapp from "./Webapp.vue"


export default {
  components: {
    Website,
    Design,
    Webapp
  },
  data() {
    return {
      comp: "Website",
    };
  },
 };

</script>


<style scoped>

.portfolio {
  width: 100%;
  min-height: 80vh;
  height: auto;
}
.title {
  text-align: center;
  font-family: "Questrial", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: rgb(80, 226, 245);
  line-height: 100px;
}

.information {
  position: relative;
  top: 70px;
}

.information ul {
  display: flex;
  justify-content: center;
}

.information ul li {
  list-style: none;
  color: rgb(80, 226, 245);
  padding: 10px;
  font-size: 20px;
  font-family: "Questrial", sans-serif;
  cursor: pointer;
}

@media only screen and (max-width: 1220px) {

.photo {
  margin-right: 0;
}

.photo img {
  position: relative;
  top: -90px;
  left: -40px;
    transition: 300ms;
}

.detail {
  width: 800px;
  margin-top: 40px;
  margin-left: 25px;
  margin-right: 25px;
  transition: 300ms;
}

.buttons {
  margin-top: 50px;
}

}

@media only screen and (max-width: 770px) {

.information ul li {
  
  padding: 10px;
}

.photo {
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

.photo img {
  position: relative;
  top: -100px;
  left: -70px;
    transition: 300ms;
}

.text {
  top: 10px;
}
}

@media only screen and (max-width: 450px) {
  
.information ul li {
  
  padding: 5px;
  font-size: 15px;
}
}

@media only screen and (max-width: 325px) {

  .detail {
    font-size: 15px;
    line-height: 30px;
  }

.buttons {
  font-size: 15px;
}

.information ul li {
  
  padding: 5px;
  font-size: 13px;
}
}

</style>
